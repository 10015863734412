import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, Label, Button, Form, Input, InputGroup, FormFeedback } from "reactstrap";


import { alerShow } from '../commonFunction'
import axios from "axios"
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import '../../assets/scss/style.css'

const Dashboard = props => {

  //meta title
  document.title = "Set Naira Transaction Configuration| Coinosh";

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  //const [formValues, setFormValues] = useState({ naira_value: '' })
  const [naira, setNaira] = useState('');
  const [commission, setCommission] = useState('');
  const [minimumWithdrawAmount, setMinimumWithdrawAmount] = useState('');


  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {

    await axios.get(`${BASE_URL}admin/get-naira-value`)
      .then(function (response) {

        console.log(response, 'response success ')

        setNaira(response.data.data.data.nairaValue);
        setCommission(response.data.data.data.commission);
        setMinimumWithdrawAmount(response.data.data.data.minimumWithdrawAmount);



      })
      .catch(function (error) {

        console.log(error, 'error.response error.response')
        alerShow('Error', error.response.data.message, 'error',error.response.data.statusCode == 401);
        // alerShow('Error', error.response, 'error');
      });
  }


  // Form validation 


  const submitNairaValueRequest = async (e) => {
e.preventDefault();
    let collectionData = { 'naira':naira,'minimumWithdrawAmount':minimumWithdrawAmount,'commission':commission }
    axios.post(BASE_URL + 'admin/set-naira-value',
      collectionData
    )
      .then(function (response) {
        alerShow('Success', response.data.message, 'success');
      })
      .catch(function (error) {
        alerShow('Error', error.response.data.message, 'error');
      });
  }

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Set Naira Transaction Configuration")}
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-4">  </CardTitle>

                  <Form className="needs-validation create-vendor"
                  onSubmit={(e) => submitNairaValueRequest(e)}
                  >
                    <Row>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">USD Value</Label>
                          <Input
                            type="number"
                            name="usd_value"
                            className="form-control"
                            value="1"
                            disabled
                            id="usd_value"
                      
                          />

                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Naira Value</Label>
                          <Input
                            type="text"
                            name="naira"
                            className="form-control"
                            value={naira}
                            id="naira"
                            onChange={(e) => {
                              setNaira(e.target.value);
                           
                            }}
                          />

                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Minimum Withdraw Amount (NGN)</Label>
                          <Input
                            type="text"
                            name="minimumWithdrawAmount"
                            className="form-control"
                            value={minimumWithdrawAmount}
                            id="minimumWithdrawAmount"
                            onChange={(e) => {
                              setMinimumWithdrawAmount(e.target.value);
                            
                            }}
                          />

                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="formrow-password-Input">Withdraw Commission (NGN)</Label>
                          <Input
                            type="text"
                            name="commission"
                            className="form-control"
                            value={commission}
                            id="commission"
                            onChange={(e) => {
                              setCommission(e.target.value);
                             
                            }}
                          />

                        </div>
                      </Col>
                    </Row>

                    <div>
                      <button type="submit" className="btn text-light w-md mt-3" style={{ backgroundColor: '#282356' }}>
                        Submit
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>

    </React.Fragment>
  );
};


Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
