import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, FormGroup, Input, InputGroup } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import Flatpickr from "react-flatpickr";

import axios from 'axios';
import moment from 'moment'
import { alerShow, dateformat, removeHeaderToken, apiHeader, convertValueFormat } from '../commonFunction'
import '../../assets/scss/style.css'
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//i18n
import { withTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";

const Dashboard = props => {
    const [totalPages, setTotalpages] = useState(10);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [show, setShow] = useState(false);
    const [banklisting, setBanklisting] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = async (username) => {

        await axios.get(`${BASE_URL}admin/get-bank-accounts?userName=` + username)
            .then(function (response) {

                console.log(response.data.data.data, 'response success ')
                // if (response.data.data.result.length > 0) {
                setBanklisting(response.data.data.data);
                //}
                setShow(true);

            })
            .catch(function (error) {
                //  setIsLoaded(true);
                console.log(error, 'error.response error.response')
                // alerShow('Error', error.response, 'error');
                alerShow('Error', error.response.data.message, 'error', error.response.data.statusCode == 401);
            });
    }
    //meta title
    document.title = "Naira Wallets | Coinosh";
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [isLoaded, setIsLoaded] = useState(false);
    const [listing, setListing] = useState([]);

    const [dateFilter, setDateFilter] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [updatedBalance, setUpdatedBalance] = useState("");
    const [email, setEmail] = useState("");


    const handleOpenModal = (email) => {
        setShowModal(true);
        setEmail(email);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleUpdateBalance = async () => {
        await axios.get(`${BASE_URL}admin/update-naira-balance?email=` + email + `&nairaBalance=` + updatedBalance)
            .then(function (response) {
                alerShow('Success', response.data.message, 'success');
                console.log(response.data.data, 'response success ');
                setShowModal(false);
                // if (response.data.data.result.length > 0) {


            })
            .catch(function (error) {
                //  setIsLoaded(true);
                console.log(error, 'error.response error.response')
                alerShow('Error', error.response.data.message, 'error', error.response.data.statusCode == 401);
                // alerShow('Error', error.response, 'error');
            });
    };




    useEffect(() => {
        fetchData(dateFilter,searchQuery);
    }, [currentPage,dateFilter,searchQuery])

    const fetchData = async (dates = '', userName = '') => {
        setIsLoaded(false)
        await axios.get(`${BASE_URL}admin/users?page=${currentPage}&limit=${perPage}&dates=` + dates + `&email=` + userName)
            .then(function (response) {
                if (response.data.status) {
                    setListing(response.data.data.result);
                    setTotalpages(response.data.totalPages);
                }
                console.log(response, 'response success ')
                // if (response.data.data.result.length > 0) {
                //}
                setIsLoaded(true);

            })
            .catch(function (error) {
                setIsLoaded(true);
                console.log(error, 'error.response error.response')
                alerShow('Error', error.response.data.message, 'error', error.response.data.statusCode == 401);
                // alerShow('Error', error.response, 'error');
            });
    }

    const handleChangeCheck = async event => {
        console.log(event.target.value)
        console.log(event.target.checked)

        let userID = event.target.value
        let isApproved = 1
        if (!event.target.checked) {
            isApproved = 0
        }

        await axios
            .post(`${BASE_URL}admin/user-update`, { id: userID, status: isApproved })
            .then(function (response) {
                alerShow('Success', response.data.message, 'success');
            })
            .catch(function (error) {
                alerShow('Error', error.response.data.error, 'error');
            })
    }
    const handleDateEvent = async (event) => {
        if (event.length == 2) {
            let filterDate = [];
            filterDate[0] = moment(event[0]).format('YYYY-MM-DD');
            filterDate[1] = moment(event[1]).add(1, 'days').format('YYYY-MM-DD');
            console.log(filterDate, 'filter date >>>>>>>')
            setDateFilter(filterDate);
            // await fetchData(filterDate);
        }else if(event.length == 0){
            setDateFilter([]);
        }
    }

    const handleUserNameEvent = async (event) => {
        setSearchQuery(event.target.value)
        // await fetchData(dateFilter, event.target.value);
    }


    return (
        <React.Fragment>

            <div className="page-content margin-custom">
                <Container fluid>
                    {/* Render Breadcrumb */}


                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody className="border-bottom py-2">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Breadcrumbs
                                            title={props.t("Dashboard")}
                                            breadcrumbItem={props.t("Naira Wallets Listing")}
                                        />
                                        <div className="d-flex align-items-center">
                                            <FormGroup className="me-3" style={{}}>
                                                <InputGroup >
                                                    <Input type="text" value={searchQuery} onChange={(e) => handleUserNameEvent(e)} placeholder="Search by UserName" />
                                                </InputGroup>
                                            </FormGroup>

                                            <Flatpickr className="form-control d-block mb-3 me-4" style={{ width: '200px', marginRight: '8px' }} onChange={(date) => handleDateEvent(date)} placeholder="Select Dates" options={{ mode: "range", dateFormat: "Y-m-d" }} />
                                            {/* <div className="flex-shrink-0"> */}
                                            {/* <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                        </div> */}
                                        </div>


                                        {/* <div className="flex-shrink-0">
                                            <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                        </div> */}
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>S. No.</th>
                                                    <th>UserName</th>
                                                    <th>Wallet Balance (NGN)</th>
                                                    <th>No. of bank accounts attached</th>
                                                    <th>Total Amount Of Sells(NGN)</th>
                                                    <th>Total Amount Of Withdraws(NGN)</th>
                                                    <th>CreatedAt</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (!isLoaded) ?
                                                        <Tr>
                                                            <Td colSpan="8"> Loading ... </Td>
                                                        </Tr>
                                                        : (listing) ?
                                                            listing.map((value, ind) => {

                                                                return <Tr key={value._id}>
                                                                    <Td scope="row"> <span className="co-name"> {(((currentPage - 1) * perPage) + ind + 1)} </span> </Td>
                                                                    <Td> {value.email} </Td>
                                                                    <Td>

                                                                        <button className="btn border-0" variant="primary" onClick={() => handleOpenModal(value.email)}>
                                                                            {/* {value.nairaBalance} */}
                                                                            {convertValueFormat(value.nairaBalance)}
                                                                        </button>
                                                                    </Td>

                                                                    <Td><button className="btn border-0" variant="primary" onClick={() => handleShow(value.email)}>{value.bankAccountsAttached}</button></Td>
                                                                    <Td>{convertValueFormat(value.totalAmountSell)}</Td>
                                                                    <Td>{convertValueFormat(value.totalAmountWithdraw)}</Td>
                                                                    <Td> {moment(value.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</Td>
                                                                    <Td>

                                                                        <div className="square-switch mytoggle">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`square-switch${value._id}`}
                                                                                switch="success"
                                                                                defaultChecked={Boolean(value.isActive)}
                                                                                value={value._id}
                                                                                onClick={handleChangeCheck}
                                                                            />
                                                                            <label
                                                                                htmlFor={`square-switch${value._id}`}
                                                                                data-on-label="Active"
                                                                                data-off-label="Inactive"
                                                                            />
                                                                        </div>
                                                                    </Td>
                                                                </Tr>
                                                            })
                                                            : <Tr> <Td colSpan="6"> No Result Found </Td> </Tr>
                                                }

                                            </tbody>
                                        </Table>
                                    </div>
                                    <Row className="mt-2">
                                        <Col md={9}>
                                        </Col>
                                        <Col md={3}>

                                            <Pagination
                                                {...bootstrap5PaginationPreset}
                                                current={currentPage}
                                                total={totalPages}
                                                onPageChange={(page) => setCurrentPage(page)}
                                                // onClick={handlePageClick}
                                                className="pagination justify-content-end"
                                            />
                                        </Col>
                                    </Row>

                                    <Modal show={show} onHide={handleClose} centered className="userAccount">
                                        <Modal.Header closeButton>
                                            <Modal.Title>Bank Account Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {
                                                (banklisting) ?
                                                    banklisting.map((value, ind) => {
                                                        return (
                                                            <ul className="list-unstyled accountDetails" key={value._id}>
                                                                <li><b>Bank Name</b>: <span className="ms-2">{value.bankName}</span></li>
                                                                <li><b>Bank Code</b>: <span className="ms-2">{value.bankCode}</span></li>
                                                                <li><b>Bank Owner Name</b>: <span className="ms-2">{value.bankOwnerName}</span></li>
                                                                <li><b>Account Number</b>: <span className="ms-2">{value.accountNumber}</span></li>
                                                            </ul>
                                                        );
                                                    }) : <ul> <li> No Result Found </li> </ul>

                                            }

                                        </Modal.Body>
                                    </Modal>

                                    <Modal show={showModal} onHide={handleCloseModal} centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Update Naira Balance</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <FormGroup>
                                                <label htmlFor="updatedBalance">New Naira Balance</label>
                                                <Input
                                                    type="text"
                                                    id="updatedBalance"
                                                    value={updatedBalance}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
                                                            setUpdatedBalance(inputValue);
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseModal}>
                                                Close
                                            </Button>
                                            <Button variant="primary" onClick={handleUpdateBalance}>
                                                Update
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    );
};


Dashboard.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
