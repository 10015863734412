import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, FormGroup, Input, InputGroup, Button } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import Flatpickr from "react-flatpickr";

import axios from 'axios';
import moment from 'moment'
import { alerShow, dateformat, removeHeaderToken, apiHeader } from '../commonFunction'
import '../../assets/scss/style.css'
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//i18n
import { withTranslation } from "react-i18next";

const Dashboard = props => {

    //meta title
    document.title = "Crypto | Coinosh";
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [isLoaded, setIsLoaded] = useState(false);
    const [walletAddresses, setWalletAddresses] = useState([]);
    const [editID, setEditID] = useState('');
    const [cryptoNetwork, setCryptoNetwork] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [listing, setListing] = useState([]);
    const [toggleStates, setToggleStates] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState("");



    const updateCrypto = () => {
        setIsLoaded(false);
        axios
            .post(`${BASE_URL}admin/cryptos-update-wallet-address`, { id: editID, wallet_address: walletAddress, cryptoNetwork: cryptoNetwork })
            .then(function (response) {
                alerShow('Success', response.data.message, 'success');
                setEditID('');
                setCryptoNetwork('');
                setWalletAddress('');
            })
            .catch(function (error) {
                alerShow('Error', error.response.data.error, 'error');
            })
            .finally(() => {
                fetchData(currentPage);
            })
    }

    useEffect(() => {
        fetchData(currentPage);
    }, [])

    const fetchData = async (page = 1) => {

        await axios.get(`${BASE_URL}admin/cryptos?page=${page}&limit=${itemsPerPage}`)
            .then(function (response) {

                console.log(response, 'response success ')
                if (response.data.data.result.length > 0) {
                    setListing(response.data.data.result);
                }
                setTotalPages(Math.ceil(response.data.totalPages));
                setIsLoaded(true);

            })
            .catch(function (error) {
                setIsLoaded(true);
                console.log(error, 'error.response error.response')
                alerShow('Error', error.response.data.message, 'error', error.response.data.statusCode == 401);
                // alerShow('Error', error.response, 'error');
            });
    }


    const handleChangeCheck = async event => {
        console.log(event.target.value)
        console.log(event.target.checked)

        let userID = event.target.value
        let isApproved = 1
        if (!event.target.checked) {
            isApproved = 0
        }

        await axios
            .post(`${BASE_URL}admin/cryptos-update`, { id: userID, status: isApproved })
            .then(function (response) {
                alerShow('Success', response.data.message, 'success');
            })
            .catch(function (error) {
                alerShow('Error', error.response.data.message, 'error');
            })
    }

    const handleChangeCheckOffRamp = async (event, rowId) => {
        console.log(event.target.value)
        console.log(event.target.checked)

        let userID = event.target.value
        let isApproved = 1
        if (!event.target.checked) {
            isApproved = 0
        }
        setToggleStates((prevStates) => ({
            ...prevStates,
            [rowId]: isApproved,
        }));

        await axios
            .post(`${BASE_URL}admin/cryptos-update-off-ramp-status`, { id: userID, status: isApproved })
            .then(function (response) {
                alerShow('Success', response.data.message, 'success');
            })
            .catch(function (error) {
                alerShow('Error', error.response.data.error, 'error', error.response.data.statusCode == 401);
            })
    }


    const handleWalletAddressChange = async (event, rowId) => {
        if (event.key === "Enter") {
            console.log(event.target.value)
            console.log(event.target.checked)
            let userID = event.target.dataset.id;

            const newWalletAddresses = [...walletAddresses];
            newWalletAddresses[rowId] = event.target.value;
            setWalletAddresses(newWalletAddresses);
            let wallet_address = event.target.value;
            await axios
                .post(`${BASE_URL}admin/cryptos-update-wallet-address`, { id: userID, wallet_address: wallet_address })
                .then(function (response) {
                    alerShow('Success', response.data.message, 'success');

                })
                .catch(function (error) {
                    alerShow('Error', error.response.data.error, 'error');
                })
        }
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(page); // Pass the updated currentPage value to fetchData
    };


    return (
        <React.Fragment>

            <div className="page-content margin-custom">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <div className="d-flex justify-content-between">
                        <Breadcrumbs
                            title={props.t("Dashboard")}
                            breadcrumbItem={props.t("Manage Crypto Sell")}
                        />
                        <Link to="/admin/add-crypto"><button className=" btn btn-primary">Add</button></Link>
                    </div>
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>S. No.</th>
                                                    <th>Crypto Name</th>
                                                    <th>Crypto Short Name</th>
                                                    <th>Is Available for Off Ramp</th>
                                                    <th>Crypto Network</th>
                                                    <th>Wallet Address</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (!isLoaded) ?
                                                        <Tr>
                                                            <Td colSpan="8"> Loading ... </Td>
                                                        </Tr>
                                                        : (listing) ?
                                                            listing.map((value, ind) => {
                                                                const serialNumber = (currentPage - 1) * itemsPerPage + ind + 1;
                                                                return <Tr key={value._id}>
                                                                    <Td scope="row"> <span className="co-name"> {serialNumber} </span> </Td>
                                                                    <Td> {value.cryptoName} </Td>
                                                                    <Td> {value.cryptoShortName} </Td>
                                                                    <Td>
                                                                        <div className="square-switch mytoggle2">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`square-switch-off-ramp${value._id}`}
                                                                                switch="success"
                                                                                defaultChecked={Boolean(value.isAvailableForOffRamp)}
                                                                                value={value._id}
                                                                                onClick={(event) => handleChangeCheckOffRamp(event, ind)}
                                                                            />
                                                                            <label
                                                                                htmlFor={`square-switch-off-ramp${value._id}`}
                                                                                data-on-label="Yes"
                                                                                data-off-label="No"
                                                                            />
                                                                        </div>
                                                                    </Td>
                                                                    <Td>
                                                                        {editID == value._id ?
                                                                            <Input
                                                                                type="select"
                                                                                name="cryptoNetwork"
                                                                                className="form-control"
                                                                                id="cryptoNetwork"
                                                                                onChange={(e) => {
                                                                                    setCryptoNetwork(e.target.value)
                                                                                }}
                                                                                value={cryptoNetwork}
                                                                            >
                                                                                <option value={""}></option>
                                                                                <option value={"BIP44"}>BIP44</option>
                                                                                <option value={"BIP49"}>BIP49</option>
                                                                                <option value={"BIP84"}>BIP84</option>
                                                                                <option value={"BIP89"}>BIP89</option>
                                                                                <option value={"ERC20"}>ERC20</option>
                                                                                <option value={"BEP20"}>BEP20</option>
                                                                                <option value={"TRC20"}>TRC20</option>
                                                                                <option value={"FANTOM"}>FANTOM</option>
                                                                                <option value={"POLYGON"}>POLYGON</option>
                                                                                <option value={"AVALANCHE"}>AVALANCHE</option>
                                                                                <option value={"ARBITRUM"}>ARBITRUM</option>
                                                                                <option value={"OPTIMISM"}>OPTIMISM</option>
                                                                            </Input>
                                                                            :
                                                                            value?.cryptoNetwork
                                                                        }
                                                                    </Td>
                                                                    {/* <Td>
                                                                        {toggleStates[ind] ? (
                                                                            <input
                                                                                type="text"
                                                                                id={`input-${ind}`}
                                                                                name={`input-${ind}`}
                                                                                placeholder="Enter wallet address here"
                                                                                data-wallet={walletAddresses[ind] || ""}
                                                                                data-id={value._id}
                                                                                onKeyDown={(event) => handleWalletAddressChange(event, ind)}

                                                                            />
                                                                        ) : ''}
                                                                    </Td> */}

                                                                    <Td>
                                                                        {
                                                                            editID == value._id
                                                                                ?
                                                                                <Input type="text" value={walletAddress} onChange={(e) => {
                                                                                    setWalletAddress(e.target.value)
                                                                                }} />
                                                                                :
                                                                                value.walletAddress
                                                                        }
                                                                    </Td>

                                                                    <Td>
                                                                        <div className="mb-2">
                                                                            {
                                                                                editID == value._id
                                                                                    ? <button type="button" onClick={updateCrypto} className="action-btn fs-5">
                                                                                        <i className="bx bx-check"></i>
                                                                                    </button>
                                                                                    : <button type="button" className="action-btn" onClick={() => {
                                                                                        setEditID(value._id);
                                                                                        setCryptoNetwork(value?.cryptoNetwork || "");
                                                                                        setWalletAddress(value.walletAddress);
                                                                                    }}><i className="bx bx-edit"></i></button>
                                                                            }
                                                                        </div>
                                                                        <div className="square-switch mytoggle">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`square-switch${value._id}`}
                                                                                switch="success"
                                                                                defaultChecked={Boolean(value.isActive)}
                                                                                value={value._id}
                                                                                onClick={handleChangeCheck}
                                                                            />
                                                                            <label
                                                                                htmlFor={`square-switch${value._id}`}
                                                                                data-on-label="Active"
                                                                                data-off-label="Inactive"
                                                                            />
                                                                        </div>
                                                                    </Td>
                                                                </Tr>
                                                            })
                                                            : <Tr> <Td colSpan="6"> No Result Found </Td> </Tr>
                                                }

                                            </tbody>
                                        </Table>
                                        {(isLoaded) && <Pagination
                                            current={currentPage}
                                            total={totalPages} // Replace 'listing' with the appropriate total number of items
                                            itemsPerPage={itemsPerPage}
                                            onPageChange={handlePageChange}
                                            {...bootstrap5PaginationPreset}
                                        />}
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    );
};


Dashboard.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
