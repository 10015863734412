import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import { Link } from "react-router-dom"

import axios from 'axios';
import moment from 'moment'
import { alerShow, dateformat, removeHeaderToken, apiHeader } from '../commonFunction'

const CardUser = ({ StatsTransaction, sendGraph, swapGraph, dataColors }) => {

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [status, setStatus] = useState(false);
  const sendArr = [];
  const swapArr = [];
  // const [sendGraph, setSendGraph] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  // const [swapGraph, setSwapGraph] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  // const [cryptoPrice, setCryptoPrice] = useState({ 'BTCUSDT': 0, 'ETHUSDT': 0, 'BNBUSDT': 0, 'MATICUSDT': 0, 'AVAXUSDT': 0 });

  // useEffect(() => {
  //   getGraphDetails();
  // }, [])

  const apexCardUserChartColors = getChartColorsArray(dataColors);

  console.log(swapGraph, sendGraph, 'sendGraph >>>>>>>>>')
  const series = [
    {
      name: "Sell Transactions",
      data: sendGraph,// [18, 25, 35, 45, 50, 55, 58, 68, 70, 78, 88, 96],
    },
    {
      name: "Withdraw Transactions",
      data: swapGraph,// [14, 20, 26, 30, 40, 45, 50, 68, 72, 85, 96, 110],
    },
  ]

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: apexCardUserChartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    yaxis: {
      labels: {
        formatter: function(val) {
          return val.toLocaleString()
         }
      } 
    }
  }

  return (
    <React.Fragment>
        <Card>
          <CardBody>
            <Row>
              <Col lg="2 flex-3"style={{ paddingLeft: '55px' }}>
                <div className="mt-4">
                  <p>Total Transactions</p>
                  <h4>{StatsTransaction.totalTransactions}</h4>

                  <Row className="mt-4">
                    <Col xs="12" className="mt-4 mb-3">
                      <div>
                        <p className="mb-2">Sell Transaction Amount</p>
                        <h5>{(parseFloat(StatsTransaction.totalSellAmount) || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} NGN</h5>

                      </div>
                    </Col>
                    <Col xs="12" className="mt-4">
                      <div>
                        <p className="mb-2">Withdraw Transaction Amount</p>
                        
                        <h5>{(parseFloat(StatsTransaction.totalWithdrawAmount) || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} NGN</h5>

                      </div>
                    </Col>
                  </Row>

                </div>
              </Col>

              <Col lg="10 flex-9" sm="6" >
                <div>
                  <div id="wallet-balance-chart">

                    <ReactApexChart
                      options={options}
                      series={series}
                      type="area"
                      height={300}
                      className="apex-charts"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>

        </Card>
    </React.Fragment>
  );
};

CardUser.propTypes = {
  options: PropTypes.any,
  series: PropTypes.any
};

export default CardUser;
