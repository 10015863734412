import PropTypes from "prop-types";
import React from "react";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, Label, Button, Form, Input, InputGroup, FormFeedback } from "reactstrap";


import { alerShow } from '../commonFunction'
import axios from "axios"
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import '../../assets/scss/style.css'

const Dashboard = props => {

  //meta title
  document.title = "Add Crypto| Coinosh";

  const BASE_URL = process.env.REACT_APP_BASE_URL;




  // Form validation 
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      cryptoName: '',
      cryptoShortName: '',
      walletAddress: '',
      cryptoNetwork: ''
    },
    validationSchema: Yup.object({
      cryptoName: Yup.string().required("Please Enter Crypto Name"),
      cryptoShortName: Yup.string().required("Please Enter Crypto Symbol"),
      walletAddress: Yup.string().optional(),
      // required("Please Enter Crypto Wallet Address"),
      cryptoNetwork: Yup.string().optional()
    }),
    onSubmit: (values) => {
      console.log(values, 'form data >>>>>>>>>>>>>>')
      axios.post(BASE_URL + 'admin/store-crypto', values)
        .then(function (response) {
          console.log(response)
          alerShow('Success', response.data.message, 'success');
          setTimeout(() => {
            window.location.href = 'cryptos';
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alerShow('Error', error.response.data.message, 'error', error.response.data.statusCode == 401);
        });
    }
  });

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Add Crypto")}
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardTitle className="h5 mb-4">  </CardTitle>

                  <Form className="needs-validation create-vendor"
                    onSubmit={validation.handleSubmit}
                  >
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="cryptoName">Crypto Name</Label>
                          <Input
                            type="text"
                            name="cryptoName"
                            className="form-control"
                            id="cryptoName"
                            onChange={validation.handleChange}
                            value={validation.values.cryptoName}
                          />
                          {validation.touched.cryptoName && validation.errors.cryptoName && <p className="text-danger">{validation.errors.cryptoName}</p>}
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="cryptoShortName">Crypto Symbol</Label>
                          <Input
                            type="text"
                            name="cryptoShortName"
                            className="form-control"
                            id="cryptoShortName"
                            onChange={validation.handleChange}
                            value={validation.values.cryptoShortName}
                          />
                          {validation.touched.cryptoShortName && validation.errors.cryptoShortName && <p className="text-danger">{validation.errors.cryptoShortName}</p>}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="walletAddress">Wallet Address</Label>
                          <Input
                            type="text"
                            name="walletAddress"
                            className="form-control"
                            id="walletAddress"
                            onChange={validation.handleChange}
                            value={validation.values.walletAddress}
                          />
                          {validation.touched.walletAddress && validation.errors.walletAddress && <p className="text-danger">{validation.errors.walletAddress}</p>}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label htmlFor="cryptoNetwork">Crypto Network</Label>
                          <Input
                            type="select"
                            name="cryptoNetwork"
                            className="form-control"
                            id="cryptoNetwork"
                            onChange={validation.handleChange}
                            value={validation.values.cryptoNetwork}
                          >
                            <option value={""}></option>
                            <option value={"BIP44"}>BIP44</option>
                            <option value={"BIP49"}>BIP49</option>
                            <option value={"BIP84"}>BIP84</option>
                            <option value={"BIP89"}>BIP89</option>
                            <option value={"ERC20"}>ERC20</option>
                            <option value={"BEP20"}>BEP20</option>
                            <option value={"TRC20"}>TRC20</option>
                            <option value={"FANTOM"}>FANTOM</option>
                            <option value={"POLYGON"}>POLYGON</option>
                            <option value={"AVALANCHE"}>AVALANCHE</option>
                            <option value={"ARBITRUM"}>ARBITRUM</option>
                            <option value={"OPTIMISM"}>OPTIMISM</option>
                          </Input>
                          {validation.touched.cryptoNetwork && validation.errors.cryptoNetwork && <p className="text-danger">{validation.errors.cryptoNetwork}</p>}
                        </div>
                      </Col>
                    </Row>

                    <div>
                      <button type="submit" className="btn text-light w-md mt-3" style={{ backgroundColor: '#282356' }}>
                        Submit
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>

    </React.Fragment>
  );
};


Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
