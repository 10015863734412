import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle } from "reactstrap";
//import action
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import axios from 'axios';
import moment from 'moment'
import { alerShow, dateformat, removeHeaderToken, apiHeader } from '../commonFunction'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { copyText, showSlicedText } from "helpers/common";

// Pages Components
import CardUser from "./CardUser";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import '../../assets/scss/style.css'

const Dashboard = props => {

  //meta title
  document.title = "Dashboard | Coinosh";
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [isLoaded, setIsLoaded] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [totalPages, setTotalpages] = useState(0);
  const [totalRecord, setTotalRecord] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  let [incermentInd, setIncrementInd] = useState(1);
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [listing, setListing] = useState([]);
  const [copied, setCopied] = useState("");

  const [sendGraph, setSendGraph] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [swapGraph, setSwapGraph] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [cryptoPrice, setCryptoPrice] = useState({ 'BTCUSDT': 0, 'ETHUSDT': 0, 'BNBUSDT': 0, 'MATICUSDT': 0, 'AVAXUSDT': 0 });
  const [StatsTransaction, setStatsTransaction] = useState({ 'totalWithdrawAmount': 0, 'totalCommission': 0, 'totalSellAmount': 0, 'totalTransactions': 0, 'totalUsers': 0, 'totalNaira': 0, 'totalWithdrawTransactions': 0 });

  useEffect(() => {
    getCurrentPrice();
  }, [])

  useEffect(() => {
    apiHeader();
    fetchData(1, 5);
  }, [])
  function copyContent(text) {
    copyText(text);
    setCopied(text);
    setTimeout(() => setCopied(""), 3000);
  }

  const getCurrentPrice = async () => {
    removeHeaderToken();
    await axios.get('https://api.binance.com/api/v3/ticker/price?symbols=%5B%22ETHUSDT%22,%22BNBUSDT%22,%22MATICUSDT%22,%22BTCUSDT%22,%22AVAXUSDT%22%5D')
      .then(async function (response) {

        var objectValue = {};
        for (var value of response.data) {
          objectValue[value.symbol] = value.price;
        }
        await getGraphDetails(objectValue);
        await getStatsPrice(objectValue);
        setCryptoPrice(objectValue);
      })
      .catch((err) => {
        // console.log(err)
      });
  }

  const getStatsPrice = async (cryptoCurrentPrice) => {

    console.log(cryptoCurrentPrice, 'cryptoCurrentPrice >>>>>>>>>>')

    await axios.get(`${BASE_URL}admin/stats/transactions`)
      .then(function (response) {

        var result = response.data.data;
        var totalWithdrawAmount = result.totalWithdrawAmount;
        var totalSellAmount = result.totalSellAmount;
        var totalUsers = result.totalUsers;
        var totalTransactions = result.totalTransactions;
        var totalCommission = result.totalCommission;
        var totalNaira = result.totalNaira;
        var totalWithdrawTransactions = result.totalWithdrawTransactions;
        //console.log(result.totalWithdrawAmount,'resultttt')
        var statsTrans = {};

        // var totalAmountStats = 0;
        // totalAmountStats += (result.BinanceAmount.length > 0) ? parseFloat(result.BinanceAmount[0].sum) * cryptoCurrentPrice.BNBUSDT : 0;
        // totalAmountStats += (result.EthereumAmount.length > 0) ? parseFloat(result.EthereumAmount[0].sum) * cryptoCurrentPrice.ETHUSDT : 0;
        // totalAmountStats += (result.PolygonAmount.length > 0) ? parseFloat(result.PolygonAmount[0].sum) * cryptoCurrentPrice.MATICUSDT : 0;
        // totalAmountStats += (result.AvalancheAmount.length > 0) ? parseFloat(result.AvalancheAmount[0].sum) * cryptoCurrentPrice.AVAXUSDT : 0;
        // totalAmountStats += (result.BitcoinAmount.length > 0) ? parseFloat(result.BitcoinAmount[0].sum) * cryptoCurrentPrice.BTCUSDT : 0;
        // totalAmountStats += (result.ArbitrumAmount.length > 0) ? parseFloat(result.ArbitrumAmount[0].sum) * cryptoCurrentPrice.ETHUSDT : 0;

        // var totalCommissionStats = 0;
        // totalCommissionStats += (result.BinanceCommission.length > 0) ? parseFloat(result.BinanceCommission[0].sum) * cryptoCurrentPrice.BNBUSDT : 0;
        // totalCommissionStats += (result.EthereumCommission.length > 0) ? parseFloat(result.EthereumCommission[0].sum) * cryptoCurrentPrice.ETHUSDT : 0;
        // totalCommissionStats += (result.PolygonCommission.length > 0) ? parseFloat(result.PolygonCommission[0].sum) * cryptoCurrentPrice.MATICUSDT : 0;
        // totalCommissionStats += (result.AvalancheCommission.length > 0) ? parseFloat(result.AvalancheCommission[0].sum) * cryptoCurrentPrice.AVAXUSDT : 0;
        // totalCommissionStats += (result.BitcoinCommission.length > 0) ? parseFloat(result.BitcoinCommission[0].sum) * cryptoCurrentPrice.BTCUSDT : 0;
        // totalCommissionStats += (result.ArbitrumCommission.length > 0) ? parseFloat(result.ArbitrumCommission[0].sum) * cryptoCurrentPrice.ETHUSDT : 0;

        // var totalCommissionSwap = 0;
        // totalCommissionSwap += (result.BinanceSwapCommission.length > 0) ? parseFloat(result.BinanceSwapCommission[0].sum) * cryptoCurrentPrice.BNBUSDT : 0;
        // totalCommissionSwap += (result.EthereumSwapCommission.length > 0) ? parseFloat(result.EthereumSwapCommission[0].sum) * cryptoCurrentPrice.ETHUSDT : 0;
        // totalCommissionSwap += (result.PolygonSwapCommission.length > 0) ? parseFloat(result.PolygonSwapCommission[0].sum) * cryptoCurrentPrice.MATICUSDT : 0;
        // totalCommissionSwap += (result.AvalancheSwapCommission.length > 0) ? parseFloat(result.AvalancheSwapCommission[0].sum) * cryptoCurrentPrice.AVAXUSDT : 0;
        // totalCommissionSwap += (result.BitcoinSwapCommission.length > 0) ? parseFloat(result.BitcoinSwapCommission[0].sum) * cryptoCurrentPrice.BTCUSDT : 0;
        // totalCommissionSwap += (result.ArbitrumSwapCommission.length > 0) ? parseFloat(result.ArbitrumSwapCommission[0].sum) * cryptoCurrentPrice.ETHUSDT : 0;

        setStatsTransaction({ 'totalWithdrawAmount': totalWithdrawAmount, 'totalCommission': totalCommission, 'totalSellAmount': totalSellAmount, 'totalTransactions': totalTransactions, 'totalUsers': totalUsers, 'totalNaira': totalNaira, 'totalWithdrawTransactions': totalWithdrawTransactions });
        return true;
      })
      .catch(function (error) {
        console.log(error)
      });
  }


  const getGraphDetails = async (cryptoPricea) => {

    await axios.get(`${BASE_URL}admin/dashboard/graph`)
      .then(function (response) {
        console.log(response.data.data, 'graphhhhhhhhh');
        var blank = [];
        var swapBlank = [];

        var sendGraphSet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        var swapGraphSet = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (var value of response.data.data.sellData) {

          if (!Array.isArray(blank[moment(value.createdAt).format('MMM')])) {
            blank[moment(value.createdAt).format('MMM')] = [];
          }
          blank[moment(value.createdAt).format('MMM')].push(value.amounts)

        }
        for (var value of response.data.data.withdrawData) {

          if (!Array.isArray(swapBlank[moment(value.createdAt).format('MMM')])) {
            swapBlank[moment(value.createdAt).format('MMM')] = [];
          }
          swapBlank[moment(value.createdAt).format('MMM')].push(value.amounts)
        }
        for (var month in blank) {
          var result = calculateMonthCount(blank[month]);



          if (month == 'Jan') { sendGraphSet[0] = result.toFixed(5); }
          if (month == 'Feb') { sendGraphSet[1] = result.toFixed(5); }
          if (month == 'Mar') { sendGraphSet[2] = result.toFixed(5); }
          if (month == 'Apr') { sendGraphSet[3] = result.toFixed(5); }
          if (month == 'May') { sendGraphSet[4] = result.toFixed(5); }
          if (month == 'Jun') { sendGraphSet[5] = result.toFixed(5); }
          if (month == 'Jul') { sendGraphSet[6] = result.toFixed(5); }
          if (month == 'Aug') { sendGraphSet[7] = result.toFixed(5); }
          if (month == 'Sep') { sendGraphSet[8] = result.toFixed(5); }
          if (month == 'Oct') { sendGraphSet[9] = result.toFixed(5); }
          if (month == 'Nov') { sendGraphSet[10] = result.toFixed(5); }
          if (month == 'Dec') { sendGraphSet[11] = result.toFixed(5); }
        }
        setSendGraph(sendGraphSet);

        for (var monthSwap in swapBlank) {
          var result = calculateMonthCount(swapBlank[monthSwap]);
          if (monthSwap == 'Jan') { swapGraphSet[0] = result.toFixed(5); }
          if (monthSwap == 'Feb') { swapGraphSet[1] = result.toFixed(5); }
          if (monthSwap == 'Mar') { swapGraphSet[2] = result.toFixed(5); }
          if (monthSwap == 'Apr') { swapGraphSet[3] = result.toFixed(5); }
          if (monthSwap == 'May') { swapGraphSet[4] = result.toFixed(5); }
          if (monthSwap == 'Jun') { swapGraphSet[5] = result.toFixed(5); }
          if (monthSwap == 'Jul') { swapGraphSet[6] = result.toFixed(5); }
          if (monthSwap == 'Aug') { swapGraphSet[7] = result.toFixed(5); }
          if (monthSwap == 'Sep') { swapGraphSet[8] = result.toFixed(5); }
          if (monthSwap == 'Oct') { swapGraphSet[9] = result.toFixed(5); }
          if (monthSwap == 'Nov') { swapGraphSet[10] = result.toFixed(5); }
          if (monthSwap == 'Dec') { swapGraphSet[11] = result.toFixed(5); }
        }
        setSwapGraph(swapGraphSet);
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  const calculateMonthCount = (data) => {
    var count = 0;
    for (var value of data) {
      count += parseFloat(value);
    }
    return count;
  }


  const fetchData = async () => {

    await axios.get(`${BASE_URL}admin/get-sell-transactions`)
      .then(function (response) {

        console.log(response, 'response success ')

        setListing(response.data.data.result);

        setIsLoaded(true);

      })
      .catch(function (error) {
        setIsLoaded(true);
        alerShow('Error', error.response.data.message, 'error', error.response.data.statusCode == 401);
        // alerShow('Error', error.response, 'error');
      });
  }

  return (
    <React.Fragment>
      <div className="page-content margin-custom">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>

            <Card className="mini-stats-wid col-lg-4 col-xxl-2">
              <CardBody>
                <Link to="/admin/users" >
                  <p> </p>
                  <div className="d-flex">
                    <div className="me-4 align-self-center">
                      <i className="mdi mdi-chart-box-outline h2 text-success mb-0" />
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-2">Total Naira Wallets </p>
                      <h5 className="mb-0">
                        <span className="font-size-14 text-muted">
                          {StatsTransaction.totalUsers}
                        </span>
                      </h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
            <Card className="mini-stats-wid col-lg-4 col-xxl-2">
              <CardBody>
                <Link to="/admin/users" >
                  <p> </p>
                  <div className="d-flex">
                    <div className="me-4 align-self-center">
                      <i className="mdi mdi-chart-box-outline h2 text-success mb-0" />
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-2">Total Naira Balance </p>
                      <h5 className="mb-0">
                        <span className="font-size-14 text-muted">
                          <div>{(StatsTransaction?.totalNaira || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} NGN</div>

                        </span>
                      </h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
            <Card className="mini-stats-wid col-lg-4 col-xxl-2">
              <CardBody>
                <Link to="/admin/sell-transactions" >
                  <p> </p>
                  <div className="d-flex">
                    <div className="me-4 align-self-center">
                      <i className="mdi mdi-chart-box-outline h2 text-success mb-0" />
                    </div>
                    <div className="flex-grow-1">
                      <p className="text-muted mb-2">Total Sell Amount </p>
                      <h5 className="mb-0">
                        <span className="font-size-14 text-muted">
                          <div>{(StatsTransaction.totalSellAmount || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} NGN</div>

                        </span>
                      </h5>
                    </div>
                  </div>
                </Link>
              </CardBody>
            </Card>
            <Col xxl="6">
              <Card className="mini-stats-wid">
                <CardBody className="row">
                  <h6>Withdraws</h6>
                  <Col lg="4">
                    <Link to="/admin/withdraw-transactions" >
                      <div className="d-flex">
                        <div className="me-4 align-self-center">
                          <i className="mdi mdi-chart-box-outline h2 text-success mb-0" />
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Total Withdraw</p>
                          <h5 className="mb-0">
                            <span className="font-size-14 text-muted">
                              <div>{(StatsTransaction.totalWithdrawAmount || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} NGN</div>


                            </span>
                          </h5>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col lg="4">
                    <Link to="/admin/withdraw-transactions" >
                      <div className="d-flex">
                        <div className="me-4 align-self-center">
                          <i className="mdi mdi-chart-box-outline h2 text-success mb-0" />
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Total Commission </p>
                          <h5 className="mb-0">
                            <span className="font-size-14 text-muted">
                              <div>{(StatsTransaction.totalCommission || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} NGN</div>


                            </span>
                          </h5>
                        </div>
                      </div>
                    </Link>
                  </Col>
                  <Col lg="4">
                    <Link to="/" >
                      <div className="d-flex">
                        <div className="me-4 align-self-center">
                          <i className="mdi mdi-chart-box-outline h2 text-success mb-0" />
                        </div>
                        <div className="flex-grow-1">
                          <p className="text-muted mb-2">Total Transactions </p>
                          <h5 className="mb-0">
                            <span className="font-size-14 text-muted">
                              {StatsTransaction.totalWithdrawTransactions}
                            </span>
                          </h5>
                        </div>
                      </div>
                    </Link>
                  </Col>
                </CardBody>
              </Card>
            </Col>


            <Col xl="12">
              <CardUser StatsTransaction={StatsTransaction} cryptoPrice={cryptoPrice} sendGraph={sendGraph} swapGraph={swapGraph} dataColors='["--bs-primary", "--bs-warning"]' />
            </Col>
          </Row>
          <Card>
            <CardBody className="border-bottom py-2">
              <div className="d-flex align-items-center">
                <h5 className="mb-0 card-title flex-grow-1"> Latest Sell Transactions </h5>

                <div className="flex-shrink-0">
                  <Link to="/admin/sell-transactions" className="btn btn-light me-1"> View All </Link>
                </div>
              </div>
            </CardBody>
            <CardBody className="pt-0">
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>S. No.</th>
                      <th>UserName</th>

                      <th>Sent Coin Value</th>
                      <th>Transferred To</th>
                      <th>Transaction ID</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (!isLoaded) ?
                        <Tr>
                          <Td colSpan="8"> Loading ... </Td>
                        </Tr>
                        : (listing) ?
                          listing.slice(0, 5).map((value, ind) => {
                            // Only display the first 5 elements of the array

                            return <Tr key={value._id}>
                              <Td scope="row"> <span className="co-name"> {(ind + 1)} </span> </Td>
                              <Td> {value.userName}</Td>

                              <Td> {value.sentCoinValue} {value.coin}</Td>
                              <Td>
                                <span className="me-1">
                                  {showSlicedText(value?.transferredTo)}
                                </span>
                                <OverlayTrigger overlay={<Tooltip>{copied == value?.transferredTo ? "copied" : "copy"}</Tooltip>}>
                                  <i style={{ color: '#556ee6' }} onClick={() => copyContent(value?.transferredTo)} className="bx bx-copy-alt"></i>
                                </OverlayTrigger>
                              </Td>
                              <Td>
                                <span className="me-1">
                                  {showSlicedText(value?.transactionId)}
                                </span>
                                {
                                  value?.transactionId &&
                                  <OverlayTrigger overlay={<Tooltip>{copied == value?.transactionId ? "copied" : "copy"}</Tooltip>}>
                                    <i style={{ color: '#556ee6' }} onClick={() => copyContent(value?.transactionId)} className="bx bx-copy-alt"></i>
                                  </OverlayTrigger>
                                }
                              </Td>
                              <Td> {moment(value.createdAt).format('MMMM D,YY, h:mm:ss a')}</Td>
                            </Tr>
                          })
                          : <Tr> <Td colSpan="6"> No Result Found </Td> </Tr>
                    }

                  </tbody>
                </Table>
              </div>

            </CardBody>
          </Card>
        </Container>
      </div>

    </React.Fragment>
  );
};


Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
