import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Container, Badge, CardTitle, FormGroup, Input, InputGroup } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import moment from 'moment';
// import Flatpickr from "react-flatpickr";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from 'axios';

import { alerShow, dateformat, removeHeaderToken, apiHeader, convertValueFormat } from '../commonFunction'
import '../../assets/scss/style.css'
import Pagination, { bootstrap5PaginationPreset } from 'react-responsive-pagination';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//i18n
import { withTranslation } from "react-i18next";
import { copyText, showSlicedText } from "helpers/common";

const Dashboard = props => {

    //meta title
    document.title = "Sell Transactions | Coinosh";
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [isLoaded, setIsLoaded] = useState(false);
    const [listing, setListing] = useState([]);
    const [copied, setCopied] = useState("");
    const [dateFilter, setDateFilter] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [totalAmount, setTotalAmount] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState("");

    function copyContent(text){
        copyText(text);
        setCopied(text); 
        setTimeout(() => setCopied(""), 3000);
    }
    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = async (dates = '', userName = '', transactionId = '', page = 1) => {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        await axios.get(`${BASE_URL}admin/get-sell-transactions?dates=${dates}&userName=${userName}&transactionId=${transactionId}&page=${page}&limit=${itemsPerPage}`)
            .then(function (response) {
                setListing(response.data.data.result);
                setTotalAmount(response.data.data.totalAmountInNGN);
                setTotalPages(Math.ceil(response.data.totalPages));
                setIsLoaded(true);
            })
            .catch(function (error) {
                setIsLoaded(true);
                console.log(error, 'error.response error.response');
                alerShow('Error', error.response.data.message, 'error', error.response.data.statusCode == 401);
                // alerShow('Error', error.response, 'error');
            });
    };


    const handleDateEvent = async (event) => {


        if (event.length == 2) {
            let filterDate = [];
            filterDate[0] = moment(event[0]).format('YYYY-MM-DD');
            filterDate[1] = moment(event[1]).add(1, 'days').format('YYYY-MM-DD');

            console.log(filterDate, 'filter date >>>>>>>')
            setDateFilter(filterDate);
            await fetchData(filterDate);
        }
    }

    const handleUserNameEvent = async (event) => {

        setSearchQuery(event.target.value)
        await fetchData(dateFilter, event.target.value, transactionId);

    }
    const handleTransactionIdEvent = async (event) => {

        setTransactionId(event.target.value)
        await fetchData(dateFilter, searchQuery, event.target.value);

    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchData(dateFilter, searchQuery, transactionId, page); // Pass the updated currentPage value to fetchData
    };



    const exportToCsv = () => {
        const csvRows = [];

        // add header row
        const headerRow = ["UserName", "Sent Coin Value", "Transferred To", "Transaction ID", "Created At"];
        csvRows.push(headerRow.join(","));

        // add data rows for the current page
        listing.forEach((item) => {
            const values = [
                item.userName,
                `${item.sentCoinValue} ${item.coin}`,
                item.transferredTo,
                item.transactionId,
                moment(item.createdAt).format("MMMM D,YY, h:mm:ss a"),
            ];
            csvRows.push(values.join(","));
        });

        // download the CSV file
        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "transactions.csv";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };




    return (
        <React.Fragment>

            <div className="page-content margin-custom transactionPage">
                <Container fluid>
                    {/* Render Breadcrumb */}

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody className="border-bottom py-2 mb-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <Breadcrumbs
                                            title={props.t("Dashboard")}
                                            breadcrumbItem={props.t("Sell Transaction Listing")}
                                        />
                                        <b>Total Sell Amount: <span style={{ color: "#556ee6" }}>
                                            {/* {parseFloat(totalAmount).toFixed(2)}  */}
                                            {convertValueFormat(parseFloat(totalAmount).toFixed(2))} NGN</span></b>
                                        <div className="d-flex align-items-center ">
                                            <FormGroup className="me-3" style={{}}>
                                                <InputGroup >
                                                    <Input type="text" value={transactionId} onChange={(e) => handleTransactionIdEvent(e)} placeholder="Search by TransactionID." />
                                                </InputGroup>
                                            </FormGroup>
                                            <FormGroup className="me-3" style={{}}>
                                                <InputGroup >
                                                    <Input type="text" value={searchQuery} onChange={(e) => handleUserNameEvent(e)} placeholder="Search by UserName" />
                                                </InputGroup>
                                            </FormGroup>

                                            <Flatpickr className="form-control d-block mb-3 me-4" style={{ width: '200px', marginRight: '8px' }} onChange={(date) => handleDateEvent(date)} placeholder="Select Dates" options={{ mode: "range", dateFormat: "Y-m-d" }} />
                                            <button className="btn btn-primary mb-3" onClick={exportToCsv}>Export to CSV</button>
                                        </div>


                                        {/* <div className="flex-shrink-0">
                                            <Link to="#!" className="btn btn-light me-1"><i className="mdi mdi-refresh"></i></Link>
                                        </div> */}
                                    </div>
                                </CardBody>
                                <CardBody className="pt-0">
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>S. No.</th>
                                                    <th>UserName</th>
                                                    <th>Crypto Name</th>
                                                    <th>Crypto Amount</th>
                                                    <th>NGN Amount</th>
                                                    <th>USD Amount</th>
                                                    <th>Transferred To</th>
                                                    <th>Transaction ID</th>
                                                    <th>Created At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (!isLoaded) ?
                                                        <Tr>
                                                            <Td colSpan="8"> Loading ... </Td>
                                                        </Tr>
                                                        : (listing) ?
                                                            listing.map((value, ind) => {
                                                                const serialNumber = (currentPage - 1) * itemsPerPage + ind + 1;
                                                                return <Tr key={value._id}>
                                                                    <Td scope="row"> <span className="co-name"> {serialNumber} </span> </Td>
                                                                    <Td> {value.userName}</Td>
                                                                    <Td>{value.coin}</Td>
                                                                    <Td> {value.sentCoinValue}</Td>
                                                                    <Td>
                                                                        {/* {value.amountInNGN} */}
                                                                        {convertValueFormat(value.amountInNGN)}
                                                                    </Td>
                                                                    <Td>
                                                                        {/* {value.amountInUSD} */}
                                                                        {convertValueFormat(value.amountInUSD)}

                                                                    </Td>
                                                                    <Td> <span className="me-1">{showSlicedText(value?.transferredTo)}</span>
                                                                        <OverlayTrigger overlay={<Tooltip>{copied == value?.transferredTo ? "copied" : "copy"}</Tooltip>}>
                                                                            <i style={{color:'#556ee6'}} onClick={() =>copyContent(value?.transferredTo)} className="bx bx-copy-alt"></i>
                                                                        </OverlayTrigger>
                                                                    </Td>
                                                                    {value.coin === "BTC" ? (
                                                                        <Td>
                                                                            <a href={`https://www.blockchain.com/explorer/transactions/btc-testnet/${value.transactionId}`} target="_blank" rel="noopener noreferrer">
                                                                                {showSlicedText(value?.transactionId)}
                                                                            </a>
                                                                        </Td>
                                                                    ) : value.coin === "ETH" || value.coin === "BNB" || value.coin === "MATIC" ? (
                                                                        <Td>
                                                                            <a href={`https://goerli.etherscan.io/tx/${value.transactionId}`} target="_blank" rel="noopener noreferrer">
                                                                                {showSlicedText(value?.transactionId)}
                                                                            </a>
                                                                        </Td>
                                                                    ) : (
                                                                        <Td>
                                                                            {showSlicedText(value?.transactionId)}
                                                                        </Td>
                                                                    )}

                                                                    <Td> {moment(value?.createdAt).format('MMMM D,YY, H:mm')}</Td>
                                                                </Tr>
                                                            })
                                                            : <Tr> <Td colSpan="6"> No Result Found </Td> </Tr>
                                                }

                                            </tbody>
                                        </Table>
                                        <Pagination
                                            current={currentPage}
                                            total={totalPages} // Replace 'listing' with the appropriate total number of items
                                            itemsPerPage={itemsPerPage}
                                            onPageChange={handlePageChange}
                                            {...bootstrap5PaginationPreset}
                                        />
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>

        </React.Fragment>
    );
};


Dashboard.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(Dashboard);
